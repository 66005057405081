import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import concatClass from "discourse/helpers/concat-class";
import htmlClass from "discourse/helpers/html-class";
import { postRNWebviewMessage } from "discourse/lib/utilities";
import { SCROLLED_UP, UNSCROLLED } from "discourse/services/scroll-direction";
import not from "truth-helpers/helpers/not";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FooterNav extends Component {
  static #_ = (() => dt7948.g(this.prototype, "appEvents", [service]))();
  #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "capabilities", [service]))();
  #capabilities = (() => (dt7948.i(this, "capabilities"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "scrollDirection", [service]))();
  #scrollDirection = (() => (dt7948.i(this, "scrollDirection"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "composer", [service]))();
  #composer = (() => (dt7948.i(this, "composer"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "historyStore", [service]))();
  #historyStore = (() => (dt7948.i(this, "historyStore"), void 0))();
  _modalOn() {
    postRNWebviewMessage("headerBg", "rgb(0, 0, 0)");
  }
  _modalOff() {
    postRNWebviewMessage("headerBg", document.documentElement.style.getPropertyValue("--header_background"));
  }
  setDiscourseHubHeaderBg(hasAnActiveModal1) {
    if (!this.capabilities.isAppWebview) {
      return;
    }
    if (hasAnActiveModal1) {
      this._modalOn();
    } else {
      this._modalOff();
    }
  }
  static #_7 = (() => dt7948.n(this.prototype, "setDiscourseHubHeaderBg", [action]))();
  dismiss() {
    postRNWebviewMessage("dismiss", true);
  }
  static #_8 = (() => dt7948.n(this.prototype, "dismiss", [action]))();
  share() {
    postRNWebviewMessage("shareUrl", window.location.href);
  }
  static #_9 = (() => dt7948.n(this.prototype, "share", [action]))();
  goBack(_1, event1) {
    window.history.back();
    event1.preventDefault();
  }
  static #_10 = (() => dt7948.n(this.prototype, "goBack", [action]))();
  goForward(_1, event1) {
    window.history.forward();
    event1.preventDefault();
  }
  static #_11 = (() => dt7948.n(this.prototype, "goForward", [action]))();
  get isVisible() {
    return [UNSCROLLED, SCROLLED_UP].includes(this.scrollDirection.lastScrollDirection) && !this.composer.isOpen;
  }
  get canGoBack() {
    return this.historyStore.hasPastEntries || !!document.referrer;
  }
  get canGoForward() {
    return this.historyStore.hasFutureEntries;
  }
  static #_12 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{this.setDiscourseHubHeaderBg this.modal.activeModal}}
  
      {{#if this.capabilities.isIpadOS}}
        {{htmlClass "footer-nav-ipad"}}
      {{else if this.isVisible}}
        {{htmlClass "footer-nav-visible"}}
      {{/if}}
  
      <div class={{concatClass "footer-nav" (if this.isVisible "visible")}}>
        <div class="footer-nav-widget">
          <DButton
            @action={{this.goBack}}
            @icon="chevron-left"
            class="btn-flat btn-large"
            @disabled={{not this.canGoBack}}
            @title="footer_nav.back"
            @forwardEvent={{true}}
          />
  
          <DButton
            @action={{this.goForward}}
            @icon="chevron-right"
            class="btn-flat btn-large"
            @disabled={{not this.canGoForward}}
            @title="footer_nav.forward"
            @forwardEvent={{true}}
          />
  
          {{#if this.capabilities.isAppWebview}}
            <DButton
              @action={{this.share}}
              @icon="link"
              class="btn-flat btn-large"
              @title="footer_nav.share"
            />
  
            <DButton
              @action={{this.dismiss}}
              @icon="chevron-down"
              class="btn-flat btn-large"
              @title="footer_nav.dismiss"
            />
          {{/if}}
        </div>
      </div>
    
  */
  {
    "id": "TYy7X6/M",
    "block": "[[[1,\"\\n    \"],[1,[28,[30,0,[\"setDiscourseHubHeaderBg\"]],[[30,0,[\"modal\",\"activeModal\"]]],null]],[1,\"\\n\\n\"],[41,[30,0,[\"capabilities\",\"isIpadOS\"]],[[[1,\"      \"],[1,[28,[32,0],[\"footer-nav-ipad\"],null]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isVisible\"]],[[[1,\"      \"],[1,[28,[32,0],[\"footer-nav-visible\"],null]],[1,\"\\n    \"]],[]],null]],[]]],[1,\"\\n    \"],[10,0],[15,0,[28,[32,1],[\"footer-nav\",[52,[30,0,[\"isVisible\"]],\"visible\"]],null]],[12],[1,\"\\n      \"],[10,0],[14,0,\"footer-nav-widget\"],[12],[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn-flat btn-large\"]],[[\"@action\",\"@icon\",\"@disabled\",\"@title\",\"@forwardEvent\"],[[30,0,[\"goBack\"]],\"chevron-left\",[28,[32,3],[[30,0,[\"canGoBack\"]]],null],\"footer_nav.back\",true]],null],[1,\"\\n\\n        \"],[8,[32,2],[[24,0,\"btn-flat btn-large\"]],[[\"@action\",\"@icon\",\"@disabled\",\"@title\",\"@forwardEvent\"],[[30,0,[\"goForward\"]],\"chevron-right\",[28,[32,3],[[30,0,[\"canGoForward\"]]],null],\"footer_nav.forward\",true]],null],[1,\"\\n\\n\"],[41,[30,0,[\"capabilities\",\"isAppWebview\"]],[[[1,\"          \"],[8,[32,2],[[24,0,\"btn-flat btn-large\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"share\"]],\"link\",\"footer_nav.share\"]],null],[1,\"\\n\\n          \"],[8,[32,2],[[24,0,\"btn-flat btn-large\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"dismiss\"]],\"chevron-down\",\"footer_nav.dismiss\"]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/footer-nav.js",
    "scope": () => [htmlClass, concatClass, DButton, not],
    "isStrictMode": true
  }), this))();
}